export function openNavbar() {
    const openMenu = document.getElementById("openMenu")
    const closeMenu = document.getElementById("closeMenu")
    const hamburgerMenu = document.querySelector(".hamburgerMenu")
    const body = document.getElementById("body")

    openMenu.addEventListener("click", function() {
      hamburgerMenu.classList.toggle("hidden")
      body.classList.add("fixed")
      openMenu.classList.toggle("hidden")
      closeMenu.classList.toggle("hidden")
    })
    closeMenu.addEventListener("click", function() {
      hamburgerMenu.classList.toggle("hidden")
      body.classList.remove("fixed")
      openMenu.classList.toggle("hidden")
      closeMenu.classList.toggle("hidden")
    })
}

export function hideMenuOnScroll() {
    let lastMenuScroll = 0;
    const hideOnScrollDown = document.querySelector(".hideOnScrollDown")
    const borderBottom = document.getElementById("borderBottom")
    const logo = document.getElementById("logo")
    const openMenu = document.getElementById("openMenu")
    window.onscroll = function() {
      const currentScroll = window.scrollY;
      if (currentScroll < 10) {
        borderBottom.classList.remove("border-b", "bg-white")
        logo.classList.add("hidden")
        openMenu.classList.replace("text-black", "text-white")
      }
      else if (currentScroll >= 200) {
        hideOnScrollDown.classList.add("hidden")
      }
      if ( currentScroll > lastMenuScroll && !hideOnScrollDown.classList.contains("hidden")) {
        // down
        hideOnScrollDown.classList.add("hidden")
      } else if (currentScroll < lastMenuScroll && hideOnScrollDown.classList.contains("hidden")) {
        // up
        hideOnScrollDown.classList.remove("hidden")
        logo.classList.remove("hidden")
        borderBottom.classList.add("border-b", "bg-white")
        openMenu.classList.replace("text-white", "text-black")
      }
      lastMenuScroll = currentScroll;
    }
}

export function clickLogo() {
  const greenLogo = document.getElementById("logo")
  const whiteLogo = document.getElementById("logoWhite")
  greenLogo.addEventListener("click", function() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  })
  whiteLogo.onclick = function() {
    location.href = "../"
  }
}
/**
 * Provide the translations
 */
const translations = {
  'Greetings' : {
    nb : 'Hei',
  },
  'I thought you might like this article I came across' : {
    nb : 'Jeg kom over en artikkel jeg tenkte du ville like',
  },
  'An interesting article' : {
    nb : 'En interessant artikkel',
  },
  'Copy link' : {
    nb : 'Kopier lenke',
  },
  'Share' : {
    nb : 'Del',
  },
  'E-mail' : {
    nb : 'E-post',
  }
}

/**
 * Function to use within scripts
 */
export function transl(str) {
  // get language code from html attribute
  const languageCode = $('html').attr('lang').slice(0,2);
  // return available translation or the original
  return translations[str][languageCode] !== undefined ? translations[str][languageCode] : str;
}
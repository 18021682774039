
export function showMoreInfo() {
    const vCards = document.querySelectorAll(".vCard")
    vCards.forEach(function (vCard) {
      const showInfo = vCard.querySelector(".infoBox")
      const img = vCard.querySelector("img")
      const expandController = vCard.querySelector(".expandController")
      const expandPanel = vCard.querySelector(".expandPanel")
      vCard.addEventListener('mouseover',  () =>
      {
        showInfo.classList.remove('hidden')
        showInfo.classList.add('absolute',  'bottom-1/3', 'inset-x-0' )
        expandController.classList.remove('hidden')
        expandController.classList.add('absolute', 'bottom-2', 'inset-x-0')
        img.classList.add("opacity-25")
      })
      vCard.addEventListener('mouseout',  () => {
        showInfo.classList.remove('absolute', 'bottom-1/3', 'inset-x-0')
        showInfo.classList.add('hidden')
        expandController.classList.remove('absolute', 'bottom-2', 'inset-x-0')
        expandController.classList.add('hidden')
        img.classList.remove("opacity-25")
      })
      expandController.addEventListener("click", () => {
        expandPanel.classList.toggle("hidden")
      })
    })
}
import "./style.css"
import "./scss/typography.scss"
import "./scss/fonts.scss"
import "./scss/forms.scss"
import "./scss/cookieConsent.scss"
import "../templates/_cookie-consent/style.css"
import $ from 'jquery'
import { showMoreInfo } from "./js/employees"
import { clickLogo, hideMenuOnScroll, openNavbar } from './js/navbar'
import { navBottomScroll } from './js/bottomNav'
import { formAjax, restrictContent } from './js/forms'
import { transl } from './js/translations.js';

// make jQuery universally accessible
window.$ = $;

openNavbar();
hideMenuOnScroll();
clickLogo();
formAjax();
restrictContent();
navBottomScroll();

const host = window.location.origin + '/'
if (window.location.href === host) {
  showMoreInfo();
} else {
  // change color of menu button when not on front page
  const menuButton = document.getElementById("openMenu")
  menuButton.classList.add("text-white")
  // change color of close button when not on front page
  const closeButton = document.getElementById("xButton")
  closeButton.classList.replace("lg:text-white", "text-white")
  // Hide scroll button when not on front page
  const visible = document.getElementById("visibleOnFront")
  visible.classList.add("hidden")
  window.navBottomClick = false
  window.navBottomScroll = false
  document.getElementById('navBottom').classList.add('hidden')
}

/**
 * open form modal on click or scroll to section containing form
 **/
$("section").on("click", ".get-form", function(e) {
  e.preventDefault();
  // get the target form
  let target = $(this).data("target");
  // If modal, add classes
  if ( $(".modal.has-form#"+target).length > 0 ) {
    $(".modal.has-form#"+target).addClass("is-active");
    $(".modal.has-form#"+target).removeClass("no-animation");
    $("body").addClass("fixed")
    $("html").addClass("is-clipped");
  }
  // else scroll to section
  else {
    $("#"+target.replace('form-','')).parent().get(0).scrollIntoView({ behavior: "smooth", block: "start" });
  }
});

/**
 * Form validation
 **/
$(".is-form input, .is-form textarea").on("keyup click", function (e) {
  // check field validity
  if ($(this)[0].checkValidity()) {
    $(this).parents(".field").addClass("is-sufficient");
    $(this).parents(".field").removeClass("is-insufficient");

  } else {
    $(this).parents(".field").addClass("is-insufficient");
    $(this).parents(".field").removeClass("is-sufficient");

  }
  // check form validity
  if ($(this).parents("form")[0].checkValidity()) {
    $(this).parents("form").find(".is-submit").prop("disabled", false);
  } else {
    $(this).parents("form").find(".is-submit").prop("disabled", true);
  }
});

/**
 * Submit form
 */
$("form.is-form").on("submit", function (e) {
  e.preventDefault();
  if ($(this)[0].checkValidity()) {
    formAjax($(this));
  } else {
    console.log("Form invalid!");
  }
});

/**
 * close modals on clicks
 **/
$(".modal").on("click", ".modal-close, button.is-cancel, .modal-background", function (e) {
  e.preventDefault();
  // if content blocked we do not act on clicks
  if ($(this).parents("form[uid]").length) {
    // unless button is cancel, then we go to front page or back in history
    if ($(this).hasClass("is-cancel")) {
      $(this).addClass("is-loading");
      window.location = $('link[rel="path-home"]').attr("href").length > 10 ? $('link[rel="path-home"]').attr("href") : history.back(-1);
    }
    return;
  }
  // Remove the classes
  $(this).parents(".modal").removeClass("is-active");
  $("html").removeClass("is-clipped");
  $("body").removeClass("fixed");
});

/**
 * If page content is to be restricted
 */
if ($('.modal form[uid]').length) {
  restrictContent($('.modal form[uid]').attr("uid"));
}

/**
 * close message box on close
 **/
$("form").on("click", "button.delete", function (e) {
  e.preventDefault();
  // Remove the classes
  $(this).parent().remove();
});

/**
 * Share content
 */
$(".shareables").on("click", "a", function(e) {
  // if share button is pressed, hide it and add more buttons
  if ( $( this ).hasClass("can-share") ) {
    e.preventDefault();
    $( this ).addClass("hidden");
    $( this ).parent().append(`
				<a class="bg-primary px-8 py-1 text-white text-xs is-icon--link" href="`+window.location.href+`" >`+transl("Copy link")+`</a>
				<a class="bg-primary px-8 py-1 text-white text-xs is-icon--email" href="mailto:?subject=`+encodeURIComponent(transl("An interesting article"))+`&body=`+encodeURIComponent(transl("Greetings")+"! \r\n\r\n "+transl("I thought you might like this article I came across")+": \r\n "+window.location.href)+`">`+transl("E-mail")+`</a>
				<a class="bg-primary px-8 py-1 text-white text-xs is-icon--fb" target="_blank" href="https://facebook.com/sharer/sharer.php?u=`+encodeURIComponent(window.location.href)+`">Facebook</a>
			`);
  }
  else {
    // if copy link button is pressed we need to do some js "magic"
    if ( $( this ).hasClass("is-icon--link") ) {
      e.preventDefault();
      // execCommand needs an element's value to be selected...
      var dummy = document.createElement('input'),
        text = window.location.href;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
    // remove the added buttons and unhide the share button
    $( this ).siblings().not(".can-share").remove();
    $( this ).siblings(".can-share").removeClass("hidden");
    $( this ).remove();
  }
});

if ( $('section').is(".article, .service, .text-page, .job-listing") ) {
  $(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() > $(document).height() - 800) {
      $('#elc-cookie-consent').removeClass('is-hidden');
    }
  });
}





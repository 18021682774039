/************************
 * Form related js
 ***********************/

import Cookies from 'js-cookie';

/**
 * Handle contact form submit data
 **/
export function formAjax(formData) {
	if ( formData ) {

		// get form id
		let formId = $(formData).prop("id") ? $(formData).prop("id") : false;
		let pageUid = $(formData).attr("uid") ? $(formData).attr("uid") : false;

		// exit if requirements are not met 
		if ( formId === false || $(formData).hasClass("is-form") === false ) {
			console.log("The form did not meet the requirements.");
			return false;
		}

		// add field for API
		if ( ! formId ) {
			formData.append('<input type="hidden" name="formId" value="' + formId + '" /> ');
		}

		// add restricted UUID field for API
		if ( pageUid ) {
			formData.append('<input type="hidden" name="uid" value="' + pageUid + '" /> ');
		}

		// remove any previously added error messages
		$('form#' + formId + " .notification").remove();

		// Send it to the api
		$.post({
			url: $(formData).prop("action") + 'ajax/submit-contact-form',
			dataType: 'json',
			data: formData.serialize(),
			beforeSend: function() {
				block( $('form#' + formId + " button.is-submit") );
			},
			error: function(error) {
				// give notice an error has occured
				$('form#' + formId).prepend( divFormFeedback("is-danger", 
					"<strong>Henvendelsen ble ikke sendt inn.</strong> <br><br> Det skjedde en feil. Vennligst kontroller skjema og forsøk igjen.") ).fadeIn();
			},
			success: function(response) {
				if (response.success) {

					// if successful response from restricted content form, redirect to new URL
					if ( response.content_uid && response.url ) {
						// also set local storage so we don't ask for details repeatedly
						let contentUids = [];
						contentUids.push(response.content_uid);
						// check if it already exists and add to if so
						if ( Cookies.get('enpUids') ) {
							let enpUids = JSON.parse( Cookies.get('enpUids') );
							// add contents to new array
							$(enpUids).each( function(i,v) {
								contentUids.push(v);
							});
						}
						Cookies.set('enpUids', JSON.stringify(contentUids), { expires: 7, secure: true });

						// display content - or if failed relocate to unlocked content
						if ( ! expandContent(pageUid) ) { window.location = response.url; }

						return;
					}

					// if normal forms append success message and button
					$('form#' + formId).parent().append( divFormFeedback("is-success", 
						"<strong>Henvendelsen ble sendt inn!</strong> <br><br> Vi kommer tilbake til deg så fort som mulig. Ring oss gjerne på telefon dersom du ønsker kontakt med oss raskt.") ).fadeIn();
					$('form#' + formId).parent().append('<button class="button is-black is-cancel" type="button">Lukk</button>').fadeIn();
					// and remove form
					$('form#' + formId).remove();

				} else {
					// give notice an error has occured
                    $('form#' + formId).prepend( divFormFeedback("is-danger", 
                    	"<strong>Henvendelsen ble ikke sendt inn.</strong> <br><br> ") ).fadeIn();
				}
			},
			complete: function() {
				unBlock( $('form#' + formId + " button.is-submit") );
			},
		});
	}
}

/**
 * Apply restrictions to content when activated
 **/
export function restrictContent(pageUid) {

	// check that we got the correct setup
	if ( ! pageUid ) { return false; }
	let formId = $('form[uid="'+pageUid+'"]').attr("id");
	
	// add restrictive css
	$('.modal-background').css({'pointer-events':'none'});
	$("body > section, body > footer").not("body > .has-form").css({'filter':'blur(10px)'});
	// add restrictive classes
	$('.modal#form-'+formId).addClass("is-active");
	$("html").addClass("is-clipped");
	// remove unrestrictive elements
	$('.modal-close').remove();

	return true;
}
/**
 * Remove restrictions to content
 **/
function expandContent(pageUid) {

	// check that we got the correct setup
	if ( ! pageUid ) { return false; }
	let formId = $('form[uid="'+pageUid+'"]').attr("id");
	
	// remove restrictive css
	$("body > section, body > footer").not("body > .has-form").removeAttr('style');
	// remove restrictive classes
	$("html").removeClass("is-clipped");
	// remove restrictive elements
	$('.modal#form-'+formId).remove();

	return true;
}

/**
 * Give contact form feedback with large response field
 **/
function divFormFeedback(divType, divMessage) {
	let button = '';
	if (divType === "is-danger") {
		button = '<button type="button" class="delete"></button>';
	}
	return '<div class="notification ' + divType + '">' + button + '<p>' + divMessage + '</p></div>';
}

/**
 * Block element with loading style
 **/
function block(element) {
	$(element).addClass("is-loading");
	$(element).prop("disabled", true);
}

/**
 * Unblock loading element
 **/
function unBlock(element) {
	$(element).removeClass("is-loading");
	$(element).prop("disabled", false);
}

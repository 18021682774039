/**
 * Change nav text and link depending on viewport info
 **/

export function navBottomScroll() {
// accessible in global scope
  $(document).ready(function ($) {
    /*
     * Apply scrolling direction to top navigation
     */
    let lastScroll = 0;
    const spanTextChange = document.getElementById("spanTextChange")
    const topText = document.createTextNode("Finn ut mer")
    const whatWeDoText = document.createTextNode("Hva vi gjør")
    const whoWeAreText = document.createTextNode("Hvem vi er")
    const scrollToSection = document.getElementById("navBottom")

    // listen on scroll events and apply classes
    window.addEventListener("scroll", () => {
      const currentScroll = window.scrollY;
      if (currentScroll === 0) {
        scrollToSection.classList.add("transition-opacity", "duration-200", "ease-in", "opacity-100");
        return;
      } else if (currentScroll >= 200) {
        scrollToSection.classList.remove("transition-opacity", "duration-200", "ease-in", "opacity-100");
        scrollToSection.classList.add("transition-opacity", "duration-200", "ease-out", "opacity-0");
      } else {
        scrollToSection.classList.remove("transition-opacity", "duration-200", "ease-out", "opacity-0");
        scrollToSection.classList.add("transition-opacity", "duration-200", "ease-in", "opacity-100");
      }
      if (currentScroll > lastScroll && !scrollToSection.classList.contains("transition-opacity duration-200 ease-out opacity-0")) {
        // down
        scrollToSection.classList.add("transition-opacity", "duration-200", "ease-out", "opacity-0");
        scrollToSection.classList.remove("transition-opacity", "duration-200", "ease-in", "opacity-100");
      } else if (currentScroll < lastScroll && scrollToSection.classList.contains("transition-opacity duration-200 ease-out opacity-0")) {
        // up
        scrollToSection.classList.remove("transition-opacity", "duration-200", "ease-in", "opacity-100");
        scrollToSection.classList.add("transition-opacity", "duration-200", "ease-out", "opacity-0");
      }
      lastScroll = currentScroll;
    });

    /*
     * changes depending on items available in viewport
     */
    $.fn.isInViewport = function (style = JSON.stringify(false)) {
      let elementTop = $(this).offset().top;
      let elementBottom = elementTop + $(this).outerHeight();
      let viewportTop = $(window).scrollTop();
      let viewportBottom = viewportTop + $(window).height();

      //console.log(elementTop + ',' + elementBottom + ',' + viewportTop + ',' + viewportBottom);

      // return true if
      if (style === "bottoms") {
        // element bottom is within 100 pixels of viewport bottom
        return elementTop < viewportTop + 100 && elementBottom > viewportBottom - 100;
      } else if (style === "fully") {
        // element is 100% aligned with viewport
        return elementTop === viewportTop && elementBottom === viewportBottom;
      } else {
        // element is visible anywhere within viewport
        return elementBottom > viewportTop && elementTop < viewportBottom;
      }
    };

    // check when window resize or scroll on larger than mobile, on frontpage
    if ($("section").hasClass("frontPage")) {
      if ($(window).width() > 768) {
        $(window).on('resize scroll', function () {
          // display bottom navigation only on different sections
          if ($("#intro").isInViewport("bottoms") || $("#intro").isInViewport("fully")) {
            $( "#visibleOnFront a" ).attr("href", "#ourVision");
            spanTextChange.textContent = topText.textContent
            scrollToSection.classList.add("transition-opacity", "duration-200", "ease-in", "opacity-100");
          } else if ($("#ourVision").isInViewport("bottoms")) {
            $("#visibleOnFront a").attr("href", "#whatWeDo");
            spanTextChange.textContent = whatWeDoText.textContent
            scrollToSection.classList.add("transition-opacity", "duration-200", "ease-in", "opacity-100");
          } else if ($("#whatWeDo").isInViewport("bottoms")) {
            $("#visibleOnFront a").attr("href", "#whoWeAre");
            spanTextChange.textContent = whoWeAreText.textContent
            scrollToSection.classList.add("transition-opacity", "duration-200", "ease-in", "opacity-100");
          } else {
            $("#visibleOnFront").addClass("transition-opacity", "duration-200", "ease-out", "opacity-0");
          }
        });
      }
    }
  });
}
